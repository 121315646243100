import React, { useEffect, useState } from 'react';
import Logo from '../../components/Logo';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import config from '../../config';
import './style.scss';

export default function Payment() {
  const [iframeSrc, setIframeSrc] = useState()
  const [error, setError] = useState()
  let firstName = localStorage.getItem('register');
  if (!firstName) window.location.href = '/register'
  firstName = JSON.parse(firstName).firstName;

  useEffect(() => {
    setError(sessionStorage.getItem('errorCG'))
    sessionStorage.removeItem('errorCG');
    axios.get(`${config.api.url}/creditguard/init?siteLocation=${window.location.origin}`)
      .then((response) => {
        setIframeSrc(response.data.url)
      })
  }, [])
  return (
    <div className="payment">
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Logo color="#353D2F" />
      <div className="payment-title">
        <div>כמעט סיימנו <span className="payment-title-name">{firstName}</span></div>
        <div>אנא מלאו פרטי אשראי עבור חיובי המועדון</div>
      </div>
      <div className={`CG${error ? " error" : ""}`}>
        <iframe src={iframeSrc} />
        {error && <p className="error-text">{error}</p>}
      </div>
    </div>
  )
}
