import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import './style.scss';

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');

export default function ModalComponent({ isOpen, setIsOpen, children, classes }) {

  function closeModal() {
    setIsOpen(false);
  }
  const [props, setProps] = useState({})
  useEffect(() => {
    if (classes) setProps({overlayClassName: classes})
  }, [classes])

  return (
    <div>
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        className="modal-content"
        {...props}
      >
        <div className="modal-header">
          <button onClick={closeModal} className="modal-btn-close">x</button>
        </div>
        <div className="modal-body">
          {children}

        </div>
      </Modal>
    </div>
  );
}
