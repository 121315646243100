import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

export default function CreditguardSuccess() {
  useEffect(() => {
    console.log('window.location', window.location.search)
    const urlParams = new URLSearchParams(window.location.search);
    console.log('url params', urlParams)
    const txId = urlParams.get('txId');
    const cardToken = urlParams.get('cardToken')
    window.parent.sessionStorage.setItem('cgSuccess', JSON.stringify({ txId, cardToken }))
    window.parent.location.href = '/register';
  }, [])

  return (
    <>      <Helmet>
      <meta name="robots" content="noindex" />
    </Helmet></>
  )
}
