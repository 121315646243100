import React, { useState } from 'react';
import Logo from '../../components/Logo';
import ContactForm from './form';
import './style.scss'
import Success from './success';

export default function Contact() {
  const [step, setStep] = useState(1);

  const isSuccess = () => {
    return step === 2
  }

  return (
    <div className={`${isSuccess() ? "success " : ""}contact`}>
      <Logo color={`#${isSuccess() ? "EDECC1" : "353D2F"}`} />
      {!isSuccess() ? <ContactForm setStep={setStep} /> : <Success />}
    </div>
  )
}
