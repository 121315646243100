import React, { useEffect, useState } from 'react';
import Logo from '../../components/Logo';
import RegisterForm from './form';
import RegisterProcess from './registerProcess';
import { Helmet } from 'react-helmet';
import './style.scss'

export default function Register() {
  const [isAfterPayment, setIsAfterPayment] = useState();
  useEffect(() => {
    const cgSuccess = sessionStorage.getItem('cgSuccess')
    setIsAfterPayment(!!cgSuccess);
  }, [])
  function handleLogoClick() {
    if (isAfterPayment)
      window.location.href = '/register'
  }
  return (
    <div className="register">
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Logo color="#353D2F" onClick={handleLogoClick} />
      {isAfterPayment === false && <RegisterForm />}
      {isAfterPayment && <RegisterProcess />}
    </div>
  )
}
