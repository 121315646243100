import React, {useState} from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Loader from '../../components/Loader';
import Select from "react-select";
import { formFields, formValidationSchema, handleSubmit, BirthdayInput } from './formData';
import countriesList from '../../Utils/countries.json';
import ModalComponent from '../../components/Modal';
import PdfViewer from '../../components/PdfViewer';
import termsFile from '../../assets/files/terms.pdf';

function MyForm() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <div className="register-form">
      <div className="form-title">הרשמה למועדון וילה אכדיה</div>
      <Formik
        initialValues={formFields}
        validationSchema={formValidationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, status, values, setFieldValue }) => {
          return (
            <Form>
              <div className="field-wrapper">
                <Field type="text" name="firstName" placeholder="שם פרטי באנגלית*" />
                <div className="error-wrapper"><ErrorMessage name="firstName" /></div>
              </div>
              <div className="field-wrapper">
                <Field type="text" name="lastName" placeholder="שם משפחה באנגלית*" />
                <div className="error-wrapper"><ErrorMessage name="lastName" /></div>
              </div>
              <div className="field-wrapper">
                <Field type="email" name="email" placeholder="אימייל*" />
                <div className="error-wrapper"><ErrorMessage name="email" /></div>
              </div>
              <div className="field-wrapper">
                <Field type="tel" name="phone" placeholder="טלפון*" />
                <div className="error-wrapper"><ErrorMessage name="phone" /></div>
              </div>
              <div className="field-wrapper">
                <Field type="text" name="id" placeholder="תעודת זהות" />
                <div className="error-wrapper"><ErrorMessage name="id" /></div>
              </div>
              <div className="field-wrapper">
                <Field name="birthday" component={BirthdayInput} placeholder="תאריך לידה (dd/mm/yyyy)*" />
                <div className="error-wrapper"><ErrorMessage name="birthday" /></div>
              </div>
              <div className="field-wrapper">
                <Field name="country">
                  {() => (
                    <Select
                      menuPlacement="top"
                      id="country"
                      filterOption={(option, input) =>option.label.toLowerCase().startsWith(input.toLowerCase())}
                      placeholder="מדינה*"
                      options={countriesList}
                      components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                      classNames={{
                        container: () => 'react-select-container',
                        control: () => 'react-select-control',
                        valueContainer: () => 'react-select-value-container',
                        input: () => 'react-select-input'
                      }}
                      value={countriesList.find(
                        (option) => option.value === values.country
                      )}
                      onChange={(option) =>
                        setFieldValue("country", option.value)
                      }
                    />
                  )}
                </Field>
                <div className="error-wrapper"><ErrorMessage name="country" /></div>
              </div>
              <div className="field-wrapper field-cb-wrapper">
                <label htmlFor="approveMailing">
                  <Field type="checkbox" name="approveMailing" />
                  {' '}
                  שלחו לי הטבות ומידע רלונטי
                </label>
                <div className="error-wrapper"><ErrorMessage name="approveMailing" /></div>
              </div>
              <div className="field-wrapper field-cb-wrapper field-terms">
                <label htmlFor="terms">
                  <Field type="checkbox" name="terms" />
                  {' '}
                  הסכמה<span className='field-terms-underline' onClick={() => setIsModalOpen(true)}>לתנאי התקנון </span> <a target="_blank" href="https://www.danhotels.co.il/AboutDanhotels/PrivacyPolicy">ומדיניות הפרטיות</a>
                </label>
                <div className="error-wrapper"><ErrorMessage name="terms" /></div>
              </div>
              <div>
                <button className="register-btn" type="submit" disabled={isSubmitting}>
                  {!isSubmitting ? 'לחצו להמשך' : <Loader color="353D2F" />}
                </button>
                {status && <div className="error-wrapper error-submit-wrapper">{status}</div>}
              </div>
            </Form>
          )
        }}
      </Formik>
      <ModalComponent isOpen={isModalOpen} setIsOpen={setIsModalOpen}>
        <PdfViewer file={termsFile} />
      </ModalComponent>
    </div>
  );
}

export default MyForm;
