import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from '../../config';
import Loader from '../../components/Loader'

export default function CreditguardSuccess() {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false)
  let registerDetails;

  useEffect(() => {
    registerDetails = localStorage.getItem('register');
    registerDetails = JSON.parse(registerDetails);
    const { txId, cardToken } = JSON.parse(sessionStorage.getItem('cgSuccess'))
    sessionStorage.removeItem('cgSuccess');

    localStorage.removeItem('register')
    axios.post(`${config.api.url}/auth/register`, registerDetails)
      .then((response) => {
        if (response.data.error) {
          setIsError(true)
          setIsLoading(false)
        } else {
          axios.post(`${config.api.url}/booking/insert`, {
            guest: {
              ...registerDetails,
              ...clubParams(response.data)
            },
            cg: { txId, cardToken }
          })
            .then(res => {
              if (!res.data || res.data.error) {
                setIsError(true)
                setIsLoading(false)
              } else {
                const guestDetails = {
                  firstName: registerDetails.firstName,
                  lastName: registerDetails.lastName,
                  email: registerDetails.email,
                  total: res.data.totalPayment
                }
                if (response.data.ClubMemberNumber) {
                  guestDetails.memberNumber = response.data.ClubMemberNumber
                }
                localStorage.setItem('guestDetails', JSON.stringify(guestDetails))
                window.location.href = "/success";
              }
            })
            .finally(() => {
              setIsLoading(false)
            })
        }
      })
      .catch((error) => {
        console.error('Error sending form data:', error);
        setIsLoading(false)
      })

  }, [])
  function clubParams(registerRes) {
    return {
      club: true,
      clubCode: registerRes.ClubCode,
      guestID: registerRes.GuestId
    }
  }
  return (
    <div className="register-process">
      {!isError && <div className="register-process-title">ההרשמה מתבצעת ...</div>}
      {isError &&
        <>
          <div className="register-process-title">
            <div>אופס, משהו השתבש</div>
            <div>בואו נעשה את זה שוב</div>
          </div>
          <button className="register-process-err-btn" onClick={() => window.location.href = '/register'}>חזרה להרשמה</button>
        </>
      }
      {isLoading && <Loader color="353D2F" />}
    </div>
  )
}
