import React from 'react';

const Success = () => {
    return (
        <>
            <div className="success-title">
                <div className="success-title-smile"><span>:</span>-)</div>
                <div>Thank you for your interest,</div>
                <div>Email with more details will follow shortly.</div>
            </div>
            <button className="success-btn" onClick={() => window.location.href = '/'}>Back to HomePage</button>
        </>
    )
}

export default Success;