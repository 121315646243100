import * as Yup from 'yup';
import axios from 'axios';
import config from '../../config';

export const formFields = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  id: '',
  country: '',
  birthday: '',
  approveMailing: false,
  terms: false
}
export const formValidationSchema = Yup.object({
  firstName: Yup.string().matches(/^[a-zA-Z\s]+$/, 'שם יכול להכיל רק אותיות באנגלית').required('אנא הכניסו שם פרטי באנגלית'),
  lastName: Yup.string().matches(/^[a-zA-Z\s]+$/, 'שם יכול להכיל רק אותיות באנגלית').required('אנא הכניסו שם משפחה באנגלית'),
  email: Yup.string().email('מייל לא תקין').required('אנא הכניסו מייל'),
  phone: Yup.string().matches(/^[\d-]+$/, 'טלפון לא תקין').required('אנא הכניסו טלפון'),
  birthday: Yup.string().matches(
    /^(0[1-9]|[12]\d|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/,
    'תאריך לא תקין'
  ).test('valid-year', 'השנה צריכה להיות גדולה מ1970', (value) => {
    if (!value) return true; // Allow empty value
    const year = Number(value.slice(6, 10));
    return year > 1900;
  }).required('אנא הכניסו תאריך לידה'),
  country: Yup.string().required('אנא בחרו מדינה'),
  id: Yup.string().matches(/^[0-9]{9}$/, 'תעוזת זהות לא תקינה')
    .test('is-valid-id', 'תעודת זהות לא תקינה', (value) => {
      if (!value) {
        return true;
      }

      // Validate the ID number using the algorithm for Israeli identity cards
      let id = value.toString().trim();
      let sum = 0;
      let incNum;

      for (let i = 0; i < id.length - 1; i++) {
        incNum = parseInt(id.charAt(i), 10) * ((i % 2) + 1);
        if (incNum > 9) {
          incNum -= 9;
        }
        sum += incNum;
      }

      let checkDigit = (sum * 9) % 10;
      return checkDigit === parseInt(id.charAt(8), 10);
    }),
  terms: Yup.boolean().oneOf([true], 'יש לאשר את תנאי התקנון')
})

export function handleSubmit(values, { setSubmitting, setStatus }) {
  setSubmitting(true);
  setStatus('');
  axios.post(`${config.api.url}/auth/verify-details`, {
    email: values.email,
    id: values.id
  })
  .then((response) => {
    if (response.data.error) {
      setSubmitting(false)
      setStatus(response.data.error);
    } else {
      localStorage.setItem('register', JSON.stringify(trimForGuestValues(values)))
      window.location.href = '/payment'
    }

  })
    .catch((error) => {
      setSubmitting(false)
    })
}

const trimForGuestValues = (values) => {
  return {
    ...values,
    firstName: values.firstName.trim(),
    lastName: values.lastName.trim(),
    email: values.email.trim()
  }
}

export const BirthdayInput = ({ field, form, placeholder }) => {
  const handleChange = event => {
    const { name, value } = event.target;
    const sanitizedValue = value.replace(/[^0-9]/g, '');
    let formattedValue = '';
    if (value.length < form.values[name].length) {
      formattedValue = value;
    } else if (sanitizedValue.length <= 1) {
      formattedValue = sanitizedValue;
    } else if (sanitizedValue.length <= 3) {
      formattedValue = `${sanitizedValue.slice(0, 2)}/${sanitizedValue.slice(2)}`;
    } else {
      formattedValue = `${sanitizedValue.slice(0, 2)}/${sanitizedValue.slice(2, 4)}/${sanitizedValue.slice(4, 8)}`;
    }
    form.setFieldValue(name, formattedValue);
  };
  return (
    <input {...field} onChange={handleChange} placeholder={placeholder} />
  );
};

