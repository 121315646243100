import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Loader from '../../components/Loader';
import Select from "react-select";
import { formFields, formValidationSchema, handleSubmit, BirthdayInput } from './formData';
import countriesList from '../../Utils/countries.json';

function ContactForm({ setStep }) {
  return (
    <div className="contact-form">
      <Formik
        initialValues={formFields}
        validationSchema={formValidationSchema}
        onSubmit={async (values, { setSubmitting, setStatus }) => {
          handleSubmit(values, { setSubmitting, setStatus }, setStep);
        }}
      >
        {({ isSubmitting, status, values, setFieldValue }) => {
          return (
            <Form>
              <div className="field-wrapper">
                <Field type="text" name="firstName" placeholder="*First Name" />
                <div className="error-wrapper"><ErrorMessage name="firstName" /></div>
              </div>
              <div className="field-wrapper">
                <Field type="text" name="lastName" placeholder="*Last Name" />
                <div className="error-wrapper"><ErrorMessage name="lastName" /></div>
              </div>
              <div className="field-wrapper">
                <Field type="tel" name="phone" placeholder="*Phone Number" />
                <div className="error-wrapper"><ErrorMessage name="phone" /></div>
              </div>
              <div className="field-wrapper">
                <Field type="email" name="email" placeholder="*Email" />
                <div className="error-wrapper"><ErrorMessage name="email" /></div>
              </div>
              <div className="field-wrapper">
                <Field name="birthday" component={BirthdayInput} placeholder="*Date of Birth (dd/mm/yyyy)" />
                <div className="error-wrapper"><ErrorMessage name="birthday" /></div>
              </div>
              <div className="field-wrapper">
                <Field name="country">
                  {() => (
                    <Select
                      menuPlacement="top"
                      id="country"
                      filterOption={(option, input) => option.label.toLowerCase().startsWith(input.toLowerCase())}
                      getOptionLabel={(option) => option.label_en}
                      placeholder="*Residency"
                      options={countriesList}
                      components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                      classNames={{
                        container: () => 'react-select-container',
                        control: () => 'react-select-control',
                        valueContainer: () => 'react-select-value-container',
                        input: () => 'react-select-input'
                      }}
                      value={countriesList.find(
                        (option) => option.value === values.country
                      )}
                      onChange={(option) =>
                        setFieldValue("country", option.value)
                      }
                    />
                  )}
                </Field>
                <div className="error-wrapper"><ErrorMessage name="country" /></div>
              </div>
              <div className="field-wrapper">
                <Field type="text" name="referrer" placeholder="*Referrer" />
                <div className="error-wrapper"><ErrorMessage name="referrer" /></div>
              </div>
              <div className="field-wrapper">
                <Field type="text" name="comment" as="textarea" rows={4} placeholder="Anything Else to Include?" />
                <div className="error-wrapper"><ErrorMessage name="comment" /></div>
              </div>
              <div className="field-wrapper field-terms">
                <label htmlFor="terms">
                  <Field type="checkbox" name="terms" />
                  {' '}
                  I Agree to the <a target="_blank" href="https://www.danhotels.com/AboutDanhotels/PrivacyPolicy">Privacy Policy</a>
                </label>
                <div className="error-wrapper"><ErrorMessage name="terms" /></div>
              </div>
              <div>
                <button className="contact-btn" type="submit" disabled={isSubmitting}>
                  {!isSubmitting ? 'SEND' : <Loader color="353D2F" />}
                </button>
                {status && <div className="status-wrapper error-submit-wrapper">{status}</div>}
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  );
}

export default ContactForm;
