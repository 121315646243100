import React, { useEffect } from 'react';
import Logo from '../../components/Logo';
import { Helmet } from 'react-helmet';
import './style.scss';

export default function Success() {
  let guestDetails = localStorage.getItem('guestDetails')
  if (!guestDetails) {
    window.location.href = '/register';
  }
  guestDetails = JSON.parse(guestDetails)
  localStorage.removeItem('guestDetails')
  function handleLogoClick() {
    window.location.href = '/register'
  }
  return (
    <div className="success">
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Logo color="#EDECC1" onClick={handleLogoClick} />
      <div className="success-title">
        <div className="success-title-smile"><span>:</span>-)</div>
        <div>תודה רבה על הרשמתכם,</div>
        {guestDetails.memberNumber && <div>מספר חבר: {guestDetails.memberNumber}</div>}
        <div>שם: {guestDetails.firstName} {guestDetails.lastName}</div>
        <div>מייל: {guestDetails.email}</div>
        {guestDetails.total && <div>כרטיס האשראי חוייב בסכום של: {guestDetails.total}</div>}
      </div>
      <button className="success-btn" onClick={() => window.location.href = '/register'}>חזרה להרשמה</button>

    </div>
  )
}
