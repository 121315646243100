import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

export default function CreditguardError() {
  useEffect(() => {
    const regex = /[?&]([^=#]+)=([^&#]*)/g;
    const url = window.location.href;
    const params = {};
    let match;
    while(match = regex.exec(url)) {
        params[match[1]] = decodeURIComponent(match[2]);
    }
    sessionStorage.setItem('errorCG', params.errorText);
    window.parent.location.reload();
  }, [])

  return (
    <>      <Helmet>
      <meta name="robots" content="noindex" />
    </Helmet></>
  )
}
